import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Table, Dropdown, Icon, Button } from 'semantic-ui-react';
import FieldOperationModal from './FieldOperationModal';

const recordOptions = [
  { value: 'edit_field',       text: 'Edit Label/Formula...' },
  { value: 'remove_field',     text: 'Remove Field' }
];

const trigger = (
  <span><Icon name='ellipsis horizontal' /></span>
);

class FieldOperations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      selectedFieldIndex: null
    };

    this.handleSelectAction = this.handleSelectAction.bind(this);
    this.handleRemove       = this.handleRemove.bind(this);
    this.editField          = this.editField.bind(this);
    this.handleChange     = this.handleChange.bind(this);
  }

  render() {
    const { reportDefinition, allFields } = this.props;
    const { isModalOpen, selectedFieldIndex } = this.state;

    const operationFields = reportDefinition.fields?.map((field, index) => ({...field, originalIndex: index}))
      .filter(field => field.type === 'operation') || [];

    if (reportDefinition.fields.length <= 2 ) {
      return null;
    }

    if (operationFields.length === 0) {
      return (
        <Segment>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2em' }}>
            <Button basic onClick={() => this.setState({ isModalOpen: true, selectedFieldIndex: null })}>
              + Calculation
            </Button>
          </div>
          <FieldOperationModal
            open={isModalOpen}
            onClose={() => this.setState({ isModalOpen: false, selectedFieldIndex: null })}
            selectedFieldIndex={selectedFieldIndex}
            operationFieldsCount={operationFields.length}
            fields={allFields}
            reportDefinition={reportDefinition}
            formCalculations={[]}
            onChange={this.handleChange}
          />
        </Segment>
      );
    }

    return (
      <Segment>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1em' }}>
          <h3 style={{ margin: 0 }}>Report Calculations</h3>
          <Button basic onClick={() => this.setState({ isModalOpen: true, selectedFieldIndex: null })}>
            + Calculation
          </Button>
        </div>

        <FieldOperationModal
          open={isModalOpen}
          onClose={() => this.setState({ isModalOpen: false, selectedFieldIndex: null })}
          selectedFieldIndex={selectedFieldIndex}
          operationFieldsCount={operationFields.length}
          fields={allFields}
          reportDefinition={reportDefinition}
          formCalculations={ [] }
          onChange={this.handleChange}
        />

        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Label</Table.HeaderCell>
              <Table.HeaderCell>Calculation</Table.HeaderCell>
              <Table.HeaderCell textAlign='right' />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {operationFields.map((field) => (
              <Table.Row key={field.originalIndex}>
                <Table.Cell>{field.label}</Table.Cell>
                <Table.Cell>{field.formula}</Table.Cell>
                <Table.Cell textAlign="right">
                  <Dropdown name={field.originalIndex}
                    index={field.originalIndex}
                    trigger={trigger}
                    icon={null}
                    options={recordOptions}
                    selectOnBlur={false}
                    value={null}
                    onChange={this.handleSelectAction.bind(this)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  editField(index) {
    console.log('editField:',index);
    this.setState({
      isModalOpen: true,
      selectedFieldIndex: index
    });
  }

  handleSelectAction(evt, { index, value }) {
    switch (value) {
      case 'edit_field':
        this.editField(index);
        break;
      case 'remove_field':
        this.handleRemove(index);
        break;
    }
  }

  handleChange(newOperation, index=null) {
    const { onChange, reportDefinition } = this.props;
    const { fields, windowFunction } = reportDefinition;

    let newFields = [...fields];
    if (index !== null) {
      newFields[index] = newOperation;
    } else {
      newFields.push(newOperation);
    }

    newFields = newFields.filter((field) => field.type === 'operation');

    onChange({ windowFunction, selectedFields: newFields });
  }

  handleRemove(index) {
    const { onChange, reportDefinition } = this.props;
    const { fields, windowFunction } = reportDefinition;
    const newFields = fields.filter((field, i) => i !== index && field.type === 'operation');

    onChange({
      windowFunction,
      selectedFields: newFields
    });
  }
}

FieldOperations.propTypes = {
  allFields: PropTypes.array.isRequired,
  reportDefinition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddField: PropTypes.func.isRequired
};

export default FieldOperations;
