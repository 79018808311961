import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'semantic-ui-react';
import { computeSelectedFields } from 'utils/reportDefinitions';

const FieldOperationModal = (props) => {
  const { selectedFieldIndex, reportDefinition } = props;

  const selectedField = selectedFieldIndex !== null ? reportDefinition.fields[selectedFieldIndex] : null;

  const [label, setLabel] = useState(selectedField?.label || '');
  const [formula, setFormula] = useState(selectedField?.formula || '');
  const [formulaError, setFormulaError] = useState(null);

  useEffect(() => {
    setLabel(selectedField?.label || '');
    setFormula(selectedField?.formula || '');
  }, [selectedField?.label, selectedField?.formula]);

  const validateFormula = (value) => {
    const formulaRegex = /^[A-Z]\s*[-+]\s*[A-Z]$/;
    if (!value) {
      return 'Formula is required';
    }
    if (!formulaRegex.test(value)) {
      return 'Formula must be in format: A + B or A - B';
    }
    return null;
  };

  const handleFormulaChange = (e, { value }) => {
    setFormula(value.toUpperCase());
    if (value.length >= 3) {
      setFormulaError(validateFormula(value.toUpperCase()));
    }
  };

  const handleSubmit = () => {
    const error = validateFormula(formula);
    if (error) {
      setFormulaError(error);
      return;
    }

    const { selectedFieldIndex, operationFieldsCount, onChange, onClose } = props;

    const newOperation = {
      type: 'operation',
      field: selectedFieldIndex || operationFieldsCount,
      label,
      formula: formula.replace(/\s+/g, '')
    };

    onChange(newOperation, selectedFieldIndex);

    onClose();
  };

  const selectedFields = computeSelectedFields(props);

  const availableFields = selectedFields.map((field, index) => ({
    label: field.label || field.field?.name,
    prefix: String.fromCharCode(65 + index),
    disable: field.type === 'operation' ||
            (field.field?.data_type !== 'numeric' && field.field?.data_type !== 'date')
  }));

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Modal.Header>
        {props.fieldOperation ? 'Edit Field Operation' : 'Add Field Operation'}
      </Modal.Header>
      <Modal.Content>
        <h4>Available Fields</h4>
        <div style={{ marginBottom: '1em' }}>
          { availableFields.map((field, index) => (
            <Form.Field key={index} style={{ marginBottom: '0.5em', opacity: field.disable ? 0.5 : 1, textDecoration: field.disable ? 'line-through' : 'none' }}>
              <label>{field.prefix}. {field.label}</label>
            </Form.Field>
          ))}
        </div>

        <Form>
          <Form.Input
            label="Label"
            name="label"
            value={label}
            onChange={(e, { value }) => setLabel(value)}
            placeholder="Enter field label"
          />
          <Form.Input
            label="Formula"
            name="formula"
            value={formula}
            onChange={handleFormulaChange}
            error={formulaError}
            placeholder="Enter formula (e.g. 'A + B')"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button primary onClick={handleSubmit}>
          {props.selectedFieldIndex ? 'Update' : 'Add'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

FieldOperationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fieldOperation: PropTypes.object,
  fields: PropTypes.array.isRequired,
  reportDefinition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddField: PropTypes.func.isRequired
};

export default FieldOperationModal;
